<template>
  <v-card
    class="mx-auto mt-8"
  >
    <v-list>
      <v-list-item v-if="isPasswordInputViewMode === false">
        <v-list-item-icon>
          <v-icon>password</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <!-- <v-list-item-subtitle
                  v-if="caption"
                >
                  <span class="caption">{{ caption }}</span>
                </v-list-item-subtitle> -->
          <v-list-item-title>
            User Password
          </v-list-item-title>
          <v-list-item-subtitle>비밀번호 변경이 가능합니다.</v-list-item-subtitle>
        </v-list-item-content>
        <v-spacer />
        <v-list-item-content style="max-width: 180px;">
          <v-btn
            color="primary"
            small
            @click="isPasswordInputViewMode = true"
          >
            <v-icon
              class="mr-2"
              small
            >
              pin
            </v-icon>
            Change Password
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-icon>
          <v-icon class="mt-4">
            password
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-container
            fluid
            class="mx-0 px-0"
          >
            <v-row>
              <v-col>
                <v-text-field
                  ref="userPassword"
                  v-model="userPassword"
                  label="Change user's password"
                  required
                  :autofocus="true"
                  :type="showPasswordField ? 'text' : 'password'"
                  :append-icon="showPasswordField ? 'visibility' : 'visibility_off'"
                  @click:append="showPasswordField = !showPasswordField"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  color="primary"
                  :loading="isInprogress"
                  @click="updateUserPassword"
                >
                  Submit
                </v-btn>
                <v-btn
                  class="ml-4"
                  @click="isPasswordInputViewMode = !isPasswordInputViewMode"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import _ from 'lodash'
import Api from './model/userApi'

export default {
  props: ['userIdx'],
  data() {
    return {
      isInprogress: false,
      isPasswordInputViewMode: false,
      userPassword: '',
      showPasswordField: false
    }
  },
  watch: {
    // isPasswordInputViewMode(v) {
    //   if (v === true) {
    //     this.$refs.userPassword.focus()
    //   }
    // }
  },
  methods: {
    async updateUserPassword() {
      this.isInprogress = true
      this.userPassword = _.trim(this.userPassword)
      if (this.userPassword === '') {
        this.$bus.$emit('infoMessage', '비밀번호를 입력해주세요')
        this.$nextTick(() => this.$refs.userPassword.focus())
        this.isInprogress = false
        return
      }
      const { data: { result } } = await Api.changeUserPasswordByAdmin(this.userIdx, this.userPassword)
      if (result === true) {
        this.$bus.$emit('successMessage', '변경되었습니다.')
      } else {
        this.$bus.$emit('errorMessage', '서버 통신에 문제가 발생했습니다')
      }
      this.isInprogress = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
